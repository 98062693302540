import React from 'react'

const Badge = () => (
  <div
    className="LI-profile-badge"
    data-version="v1"
    data-size="medium"
    data-locale="en_US"
    data-type="horizontal"
    data-theme="dark"
    data-vanity="denizengin"
  >
    <a className="LI-simple-link" href="https://uk.linkedin.com/in/denizengin?trk=profile-badge">
      Deniz Engin Vehbi
    </a>
  </div>
)

export default Badge
